import React from "react"
import { Link, graphql } from "gatsby"
// import Img from "gatsby-image/withIEPolyfill"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RightArrow from "../images/right-arrow.svg"
import classNames from "classnames"

import styles from "../styles/pages/index.module.scss"
import pageStyles from "../styles/pages/page-styles.module.scss"
import berensonAdvisory from '../images/berenson-advisory-logo.png';
import berensonCapital from '../images/berenson-capital-logo.png';

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hovering: false,
      hoverid: 0,
    }
  }

  hoverEnter = event => {
    var target = event.target

    while (!target.hasAttribute("hoverid")) {
      target = target.parentElement
    }

    this.hoverIndicator = true
    this.setState({
      hoverid: parseInt(target.getAttribute("hoverid")),
      hovering: true,
    })
  }

  hoverLeave = () => {
    this.setState({
      hovering: false,
    })
  }

  render() {
    const { data, location } = this.props
    const pageData = data.allContentfulHomePage.edges[0].node
    return (
      <Layout location={location.pathname} isHomePage>
        <SEO title="Home" />

        <div className={pageStyles.pageContentContainer}>
          <div className={styles.container}>
            <div
              className={styles.statement}
              dangerouslySetInnerHTML={{
                __html: pageData.statement.childMarkdownRemark.html,
              }}
            ></div>
            <div className={styles.companyLinks}>
              <Link
                className={classNames(styles.card, {
                  [styles.highlight]:
                    1 === this.state.hoverid || !this.state.hovering,
                })}
                hoverid={1}
                to="/berenson-advisory/"
                onMouseEnter={this.hoverEnter}
                onMouseLeave={this.hoverLeave}
              >
                <div> 
                  <div className={styles.name}>
                    <img 
                      src={berensonAdvisory}
                      alt="Logo"
                      width="200px"
                      height="auto"
                    />
                  </div>  
                  <div className={styles.description}>
                    {pageData.advisoryDescription}   
                  </div>
                </div>
                <RightArrow />
              </Link> 
              <Link
                className={classNames(styles.card, {
                  [styles.highlight]:
                    2 === this.state.hoverid || !this.state.hovering,
                })}
                hoverid={2}
                to="/berenson-capital/"
                onMouseEnter={this.hoverEnter}
                onMouseLeave={this.hoverLeave}
              >
                <div>
                  <div className={styles.name}>
                    <img 
                      src={berensonCapital}
                      alt="Logo"
                      width="200px"
                      height="auto"
                    />
                  </div>
                  <div className={styles.description}>
                    {pageData.capitalDescription}  
                  </div>
                </div>
                <RightArrow />
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query {
    allContentfulHomePage {
      edges {
        node {
          statement {
            childMarkdownRemark {
              html
            }
          }
          advisoryDescription
          capitalDescription
        }
      }
    }
  }
`
